<template>


         
        <div class="mfpopup">
            <div class="overlay">
                <div class="logo"><img src="@/assets/pfimage/MyFreePhoneLogo-01W-p-500.png"/> </div>
                
            </div>
            <div class="bodydiv">
                
                <div class="toppart">
                        <div class="toplogo"><img src="@/assets/pfimage/MyFreePhoneLogo-01-p-500.508772da.png" width="150" /></div>
                        
                        <div class="toptextpart">
                            <h1>We are searching your area </h1>
                            <h1>for free phones programs.</h1>
                        </div>
                        
                </div>
                
                <div class="slider">
                    <div class="loader"></div>
                    <div class="slide-track">
                        
                        <div class="slide">
                            <img src='@/assets/pfimage/assurance-f.png' />
                        </div>
                        <div class="slide quiken">
                            <img src='@/assets/pfimage/atnt.png' />
                        </div>
                        <div class="slide">
                            <img src='@/assets/pfimage/saflink-f.png' />
                        </div>
                        <div class="slide">
                            <img src='@/assets/pfimage/true-connect-f.png' />
                        </div>
                        <div class="slide">
                            <img src='@/assets/pfimage/verizon-f.png' />
                        </div>
                        
                        <div class="slide quiken">
                            <img src='@/assets/pfimage/atnt.png' />
                        </div>
                        <div class="slide">
                            <img src='@/assets/pfimage/assurance-f.png' />
                        </div>
                        <div class="slide">
                            <img src='@/assets/pfimage/saflink-f.png' />
                        </div>
                        <div class="slide">
                            <img src='@/assets/pfimage/assurance-f.png' />
                        </div>
                        <div class="slide">
                            <img src='@/assets/pfimage/saflink-f.png' />
                        </div>
                        <div class="slide">
                            <img src='@/assets/pfimage/true-connect-f.png' />
                        </div>
                        <div class="slide">
                            <img src='@/assets/pfimage/verizon-f.png' />
                        </div>
                       
                        <div class="slide">
                            <img src='@/assets/pfimage/assurance-f.png' />
                        </div>
                        <div class="slide">
                            <img src='@/assets/pfimage/saflink-f.png' />
                        </div>
                        <div class="slide quiken">
                            <img src='@/assets/pfimage/atnt.png' />
                        </div>
                        <div class="slide">
                            <img src='@/assets/pfimage/assurance-f.png' />
                        </div>
                        <div class="slide">
                            <img src='@/assets/pfimage/saflink-f.png' />
                        </div>
                        <div class="slide">
                            <img src='@/assets/pfimage/true-connect-f.png' />
                        </div>
                        <div class="slide">
                            <img src='@/assets/pfimage/verizon-f.png' />
                        </div>
                       
                        <div class="slide">
                            <img src='@/assets/pfimage/assurance-f.png' />
                        </div>
                        <div class="slide">
                            <img src='@/assets/pfimage/saflink-f.png' />
                        </div>
                        <div class="slide quiken">
                            <img src='@/assets/pfimage/atnt.png' />
                        </div>
                        <div class="slide">
                            <img src='@/assets/pfimage/assurance-f.png' />
                        </div>
                        <div class="slide">
                            <img src='@/assets/pfimage/saflink-f.png' />
                        </div>
                        <div class="slide">
                            <img src='@/assets/pfimage/true-connect-f.png' />
                        </div>
                        <div class="slide">
                            <img src='@/assets/pfimage/verizon-f.png' />
                        </div>
                       
                        <div class="slide">
                            <img src='@/assets/pfimage/assurance-f.png' />
                        </div>
                        <div class="slide">
                            <img src='@/assets/pfimage/saflink-f.png' />
                        </div>
                        <div class="slide quiken">
                            <img src='@/assets/pfimage/atnt.png' />
                        </div>
                        <div class="slide">
                            <img src='@/assets/pfimage/assurance-f.png' />
                        </div>
                        <div class="slide">
                            <img src='@/assets/pfimage/saflink-f.png' />
                        </div>
                        <div class="slide">
                            <img src='@/assets/pfimage/true-connect-f.png' />
                        </div>
                        <div class="slide">
                            <img src='@/assets/pfimage/verizon-f.png' />
                        </div>
                     
                        <div class="slide quiken">
                            <img src='@/assets/pfimage/atnt.png' />
                        </div>
                        <div class="slide">
                            <img src='@/assets/pfimage/assurance-f.png' />
                        </div>
                        <div class="slide">
                            <img src='@/assets/pfimage/saflink-f.png' />
                        </div>
                    </div>
            
                </div>
                <div class="makecenter">

                    <div class="pltext">
                        <div class="pulstext"><b>Please wait</b> while we match you <br class="deskhide"> to the top carrier</div> 
                        <div class="stage">
                            <div class="dot-pulse"></div>
                        </div>
                    </div>
                </div>
            </div>
         </div>
  
</template>

<script>
export default {
    name: 'HelloWorld',
    data() {
        return {
            utm_source: 'internal',
            utm_medium: 'organic',
            show_loader: false,
        }
    },
    methods: {
        showLoader() {
            
            let self = this;
            self.show_loader = true;
            setTimeout(function () {
                self.redirectToCarrier();
            }, 5000);
        },
        redirectToCarrier() {
            window.location.href = "https://www.truconnect.com/?AgentId=42538&utm_source=" + this.utm_source + "&utm_medium=" + this.utm_medium;
        }
    },
    computed: {

    },
    created() {
        let urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has('utm_source')) {
            this.utm_source = urlParams.get('utm_source')
        }
        if (urlParams.has('utm_medium')) {
            this.utm_medium = urlParams.get('utm_medium')
        }
        this.showLoader();
    }
}
</script>
<style scoped>
.mfpopup {
    position: fixed;
    top: 0;
    width: 100%;
    bottom: 0;
    background: url(../../assets/pfimage/l1fnLj.jpeg);
    z-index: 9999;
}

.bodydiv {
    z-index: 10;
}

.overlay {
    background: #000000b8;
    height: 100%;
    display: block;
    width: 100%;
    position: absolute;
    z-index: 9;
}

.overlay .logo img {
    max-width: 200px;
    margin: 50px;
}

@media screen and (max-width:767px) {
    .bodydiv {
        margin: 0 20px;
        padding: 70px 0 0;
    }
}
</style>