import { createWebHistory, createRouter } from "vue-router";

import HomePage from "@/components/HomePages/HomePage.vue";
import Advetorial from "@/components/HomePages/advetorial.vue";
import loader from "@/components/HomePages/loader.vue";

const routes = [
  { 
    path: '/',
    name: 'HomePage',
    component: HomePage,
  },
  { 
    path: '/article',
    name: 'Advetorial',
    component: Advetorial,
  },
  { 
    path: '/search',
    name: 'loader',
    component: loader,
  },
 {
    path:  "/:catchAll(.*)",
    name: "404",
    component: HomePage,
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;