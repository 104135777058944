<template>
<router-view></router-view>
</template>

<script>

export default {
    name: 'App',
    data() {
        return {
            
        }
    },
    mounted() {
        
    },
    computed:{
        
    },
    methods:{
    },
    created(){
       
    }

}
</script>

<style>
  @import "./assets/css/normalize.css";
  @import "./assets/css/webflow.css";
  @import "./assets/css/my-free-phone.webflow.css";
</style>
