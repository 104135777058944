<template>
<div>
    <div class="section-15 wf-section">
        <div class="container-24 top w-container">
            <div>
                <div class="div-block-472">
                    <div class="text-block-175">Advertorial</div>
                </div>
                <h1 class="heading-76">34 Million Americans Are Paying Phone Bills That Should Be FREE.</h1>
                <p class="paragraph-56 sub"><span class="text-span-51">How new US government programs are helping Americans eliminate their phone bill.</span></p>
            </div>
            <div class="div-block-466"><img src="@/assets/images/Blogimage01.jpg" loading="lazy" srcset="@/assets/images/Blogimage01-p-500.jpeg 500w, @/assets/images/Blogimage01-p-800.jpeg 800w, @/assets/images/Blogimage01.jpg 1080w" sizes="(max-width: 479px) 100vw, (max-width: 767px) 84vw, (max-width: 991px) 79vw, 100vw" alt="" class="image-59"></div>
        </div>
        <div class="mobile-top-image"><img src="@/assets/images/Blogimage01.jpg" loading="lazy" srcset="@/assets/images/Blogimage01-p-500.jpeg 500w, @/assets/images/Blogimage01-p-800.jpeg 800w, @/assets/images/Blogimage01.jpg 1080w" sizes="100vw" alt="" class="image-66"></div>
        <div class="container-24 bottom w-container">
            <div>
                <p class="paragraph-56"><span class="text-span-55">Over 34 Million Americans are entitled to a free android smartphone</span>, unlimited talk, text and data plans through a lesser known combination of government programs.<br><br>The Lifeline Program + Affordable Connectivity Program (ACP) <span class="text-span-58">allows for low income, students, seniors and struggling families Unlimited talk, text and data to connect</span>, find a job, start an online business &amp; talk with family overseas….All for FREE!<br><br>The FCC’s Affordable Connectivity Program (ACP) is a new federal government program committed to bringing connectivity to low-income Americans. <span class="text-span-59">Qualifying households can get connected by answering a few questions and submitting your eligibility documents.</span><br>‍<span><br></span></p>
                <div class="div-block-468">
                    <div class="div-block-469"><img src="@/assets/images/Gadgets.png" loading="lazy" width="864" srcset="@/assets/images/Gadgets-p-500.png 500w, @/assets/images/Gadgets-p-800.png 800w, @/assets/images/Gadgets.png 1080w" sizes="(max-width: 479px) 100vw, (max-width: 767px) 38vw, (max-width: 991px) 35vw, 100vw" alt=""></div>
                    <div class="div-block-470">
                        <p class="paragraph-60">The Lifeline program or “Obama Phone” has been around for awhile…but now it has been <span class="text-span-56">upgraded to include smart phones + internet data service.</span><br><br>It even allows you to <span class="text-span-57">use your current phone &amp; number WITH UNLIMITED TALK, TEXT &amp; 14 GB of Data</span> with the option to purchase a tablet for $10.</p>
                    </div>
                </div>
            </div>
            <div class="div-block-463">
                <div class="div-block-471">
                    <p class="paragraph-59"><span class="text-span-52">The new program is available for anyone on:</span>‍</p>
                    <ul role="list" class="list">
                        <li class="list-item">
                            <div class="text-block-174"><span class="text-span-54">Medicaid / Medi-Cal</span></div>
                        </li>
                        <li class="list-item-2">
                            <div class="text-block-174"><span class="text-span-54">SNAP / CalFresh</span></div>
                        </li>
                        <li class="list-item-2">
                            <div class="text-block-174"><span class="text-span-54">Federal Public Housing Assistance or Section 8</span></div>
                        </li>
                        <li class="list-item-2">
                            <div class="text-block-174"><span class="text-span-54">Supplemental Security Income (SSI)</span></div>
                        </li>
                        <li class="list-item-2">
                            <div class="text-block-174"><span class="text-span-54">National School Breakfast and Lunch Program</span></div>
                        </li>
                        <li class="list-item-2">
                            <div class="text-block-174"><span class="text-span-54">Veteran and Survivors Pension Benefit</span></div>
                        </li>
                        <li class="list-item-2">
                            <div class="text-block-174"><span class="text-span-54">WIC</span></div>
                        </li>
                        <li class="list-item-2">
                            <div class="text-block-174">many more</div>
                        </li>
                    </ul>
                </div>
                <div class="div-block-459">
                    <div class="div-block-467"><img src="@/assets/images/Medicaid.png" loading="lazy" width="100" srcset="@/assets/images/Medicaid-p-500.png 500w, @/assets/images/Medicaid-p-800.png 800w, @/assets/images/Medicaid.png 1030w" sizes="(max-width: 991px) 100px, 100vw" alt=""><img src="@/assets/images/Medi-Cal.png" loading="lazy" width="80" alt=""><img src="@/assets/images/Supplemental_Nutrition_Assistance_Program_logo.svg.png" loading="lazy" width="90" srcset="@/assets/images/Supplemental_Nutrition_Assistance_Program_logo.svg-p-500.png 500w, @/assets/images/Supplemental_Nutrition_Assistance_Program_logo.svg-p-800.png 800w, @/assets/images/Supplemental_Nutrition_Assistance_Program_logo.svg-p-1080.png 1080w, @/assets/images/Supplemental_Nutrition_Assistance_Program_logo.svg.png 1200w" sizes="(max-width: 991px) 90px, 100vw" alt=""></div>
                    <div class="div-block-467"><img src="@/assets/images/Housing_and_Urban_Development.svg.png" loading="lazy" width="70" srcset="@/assets/images/Housing_and_Urban_Development.svg-p-500.png 500w, @/assets/images/Housing_and_Urban_Development.svg-p-800.png 800w, @/assets/images/Housing_and_Urban_Development.svg-p-1080.png 1080w, @/assets/images/Housing_and_Urban_Development.svg.png 1200w" sizes="(max-width: 991px) 70px, 100vw" alt=""><img src="@/assets/images/lunch-program-copy.png" loading="lazy" width="120" alt=""><img src="@/assets/images/ssi.png" loading="lazy" width="70" srcset="@/assets/images/ssi-p-500.png 500w, @/assets/images/ssi.png 512w" sizes="(max-width: 991px) 70px, 100vw" alt=""></div>
                    <div class="div-block-467"><img src="@/assets/images/Pell-grant-from-ED.jpeg" loading="lazy" width="70" alt=""><img src="@/assets/images/vA.png" loading="lazy" width="130" srcset="@/assets/images/vA-p-500.png 500w, @/assets/images/vA-p-800.png 800w, @/assets/images/vA.png 1000w" sizes="(max-width: 991px) 130px, 100vw" alt=""><img src="@/assets/images/CalFresh.png" loading="lazy" width="100" srcset="@/assets/images/CalFresh-p-500.png 500w, @/assets/images/CalFresh.png 721w" sizes="(max-width: 991px) 100px, 100vw" alt=""></div>
                </div>
            </div>
            <div>
                <div class="div-block-125">
                    <div class="div-block-135">
                        <div class="div-block-464"></div><img src="@/assets/images/icon_phone.svg" loading="lazy" width="80" alt="" class="image-65">
                        <div class="div-block-465">
                            <a href="#" class="link-block-14 w-inline-block">
                                <div class="text-block-172">Ads by RateGenie.We may be compensated if you click this </div>
                                <div class="text-block-165">Ad</div><img src="https://uploads-ssl.webflow.com/61b2357985a5519299f493fc/61b23eec3434c1b26e9c421e_icons_info.png" loading="lazy" width="20" alt="">
                            </a>
                        </div>
                        <h1 class="heading-74"><strong>The best smartphones and tablets go quick…so make sure to claim your spot in this program today.</strong></h1>
                        <p class="paragraph-58"><strong>MyFreePhone </strong>can match you to a carrier in your area who offers this progrsm. It only takes 10 minutes MAX to fill out the application to qualify.<br></p>
                        <a href="#" @click="showLoader($event)" class="button-40 w-button">See If You Qualify</a>
                        <div class="div-block-464 bottom"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="wf-section">
        <div class="div-block-79 blk"><img src="@/assets/images/MyFreePhoneLogo-01.png" loading="lazy" width="140" srcset="@/assets/images/MyFreePhoneLogo-01-p-500.png 500w, @/assets/images/MyFreePhoneLogo-01-p-800.png 800w, @/assets/images/MyFreePhoneLogo-01-p-1080.png 1080w, @/assets/images/MyFreePhoneLogo-01-p-1600.png 1600w, @/assets/images/MyFreePhoneLogo-01-p-2000.png 2000w, @/assets/images/MyFreePhoneLogo-01-p-2600.png 2600w, @/assets/images/MyFreePhoneLogo-01-p-3200.png 3200w, @/assets/images/MyFreePhoneLogo-01.png 4500w" sizes="140px" alt="" class="image-9">
            <div class="div-block-34">
                <div class="div-block-77">
                    <div class="div-block-76">
                        <div class="text-block-21">
                            <a href="https://go.arlo.ai/privacy/" target="_blank" class="link-5 blk">Privacy Policy</a>
                        </div>
                    </div>
                    <div class="div-block-76">
                        <div class="text-block-21">
                            <!--<a href="#" class="link-5 blk">Terms of Site</a>-->
                        </div>
                    </div>
                    <div class="div-block-76">
                        <div class="text-block-21">
                            <!--<a href="#" class="link-5 blk">Accessibility</a>-->
                        </div>
                    </div>
                </div>
            </div>
            <div class="div-block-75">
                <div class="div-block-74">
                    <div class="text-block-23 blk">Copyright 2022 My Free Phone</div>
                    <div class="text-block-23 blk">All Rights Reserved</div>
                </div>
            </div>
        </div>
    </div>
    <div class="section-14 wf-section">
        <div class="nav-div">
            <div class="div-block-473">
                <a href="/" class="w-nav-brand"><img src="@/assets/images/MyFreePhoneLogo-01.png" loading="lazy" width="100" srcset="@/assets/images/MyFreePhoneLogo-01-p-500.png 500w, @/assets/images/MyFreePhoneLogo-01-p-800.png 800w, @/assets/images/MyFreePhoneLogo-01-p-1080.png 1080w, @/assets/images/MyFreePhoneLogo-01-p-1600.png 1600w, @/assets/images/MyFreePhoneLogo-01-p-2000.png 2000w, @/assets/images/MyFreePhoneLogo-01-p-2600.png 2600w, @/assets/images/MyFreePhoneLogo-01-p-3200.png 3200w, @/assets/images/MyFreePhoneLogo-01.png 4500w" sizes="(max-width: 479px) 41vw, 100px" alt="" class="image-14"></a>
                <a href="#" @click="showLoader($event)" class="button-41 desk w-button">See If You Qualify</a>
                <a href="#" @click="showLoader($event)" class="button-41 mob w-button">Pre-Qualify</a>
            </div>
        </div>
    </div>
    <div class="mfpopup" v-if="show_loader">
        <div class="bodydiv">
            <div class="toppart">
                <div class="toplogo"><img src="@/assets/images/MyFreePhoneLogo-01-p-500.png" width="150" /></div>
                <div class="toptextpart">
                    <h1>We are searching your area </h1>
                    <h1>for free phones programs.</h1>
                </div>
            </div>
            <div class="slider">
                <div class="loader"></div>
                <div class="slide-track">

                    <div class="slide">
                        <img src='@/assets/pfimage/assurance-f.png' />
                    </div>
                    <div class="slide quiken">
                        <img src='@/assets/pfimage/atnt.png' />
                    </div>
                    <div class="slide">
                        <img src='@/assets/pfimage/saflink-f.png' />
                    </div>
                    <div class="slide">
                        <img src='@/assets/pfimage/true-connect-f.png' />
                    </div>
                    <div class="slide">
                        <img src='@/assets/pfimage/verizon-f.png' />
                    </div>

                    <div class="slide quiken">
                        <img src='@/assets/pfimage/atnt.png' />
                    </div>
                    <div class="slide">
                        <img src='@/assets/pfimage/assurance-f.png' />
                    </div>
                    <div class="slide">
                        <img src='@/assets/pfimage/saflink-f.png' />
                    </div>
                    <div class="slide">
                        <img src='@/assets/pfimage/assurance-f.png' />
                    </div>
                    <div class="slide">
                        <img src='@/assets/pfimage/saflink-f.png' />
                    </div>
                    <div class="slide">
                        <img src='@/assets/pfimage/true-connect-f.png' />
                    </div>
                    <div class="slide">
                        <img src='@/assets/pfimage/verizon-f.png' />
                    </div>

                    <div class="slide">
                        <img src='@/assets/pfimage/assurance-f.png' />
                    </div>
                    <div class="slide">
                        <img src='@/assets/pfimage/saflink-f.png' />
                    </div>
                    <div class="slide quiken">
                        <img src='@/assets/pfimage/atnt.png' />
                    </div>
                    <div class="slide">
                        <img src='@/assets/pfimage/assurance-f.png' />
                    </div>
                    <div class="slide">
                        <img src='@/assets/pfimage/saflink-f.png' />
                    </div>
                    <div class="slide">
                        <img src='@/assets/pfimage/true-connect-f.png' />
                    </div>
                    <div class="slide">
                        <img src='@/assets/pfimage/verizon-f.png' />
                    </div>

                    <div class="slide">
                        <img src='@/assets/pfimage/assurance-f.png' />
                    </div>
                    <div class="slide">
                        <img src='@/assets/pfimage/saflink-f.png' />
                    </div>
                    <div class="slide quiken">
                        <img src='@/assets/pfimage/atnt.png' />
                    </div>
                    <div class="slide">
                        <img src='@/assets/pfimage/assurance-f.png' />
                    </div>
                    <div class="slide">
                        <img src='@/assets/pfimage/saflink-f.png' />
                    </div>
                    <div class="slide">
                        <img src='@/assets/pfimage/true-connect-f.png' />
                    </div>
                    <div class="slide">
                        <img src='@/assets/pfimage/verizon-f.png' />
                    </div>

                    <div class="slide">
                        <img src='@/assets/pfimage/assurance-f.png' />
                    </div>
                    <div class="slide">
                        <img src='@/assets/pfimage/saflink-f.png' />
                    </div>
                    <div class="slide quiken">
                        <img src='@/assets/pfimage/atnt.png' />
                    </div>
                    <div class="slide">
                        <img src='@/assets/pfimage/assurance-f.png' />
                    </div>
                    <div class="slide">
                        <img src='@/assets/pfimage/saflink-f.png' />
                    </div>
                    <div class="slide">
                        <img src='@/assets/pfimage/true-connect-f.png' />
                    </div>
                    <div class="slide">
                        <img src='@/assets/pfimage/verizon-f.png' />
                    </div>

                    <div class="slide quiken">
                        <img src='@/assets/pfimage/atnt.png' />
                    </div>
                    <div class="slide">
                        <img src='@/assets/pfimage/assurance-f.png' />
                    </div>
                    <div class="slide">
                        <img src='@/assets/pfimage/saflink-f.png' />
                    </div>
                </div>
            </div>
            <div class="makecenter">
                <div class="pltext">
                    <div class="pulstext"><b>Please wait</b> while we match you <br class="deskhide"> to the top carrier</div>
                    <div class="stage">
                        <div class="dot-pulse"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'HelloWorld',
    data() {
        return {
            utm_source: 'internal',
            utm_medium: 'organic',
            show_loader: false,
        }
    },
    methods: {
        showLoader(event) {
            event.preventDefault();
            let self = this;
            self.show_loader = true;
            setTimeout(function () {
                self.redirectToCarrier();
            }, 5000);
        },
        redirectToCarrier() {
            window.location.href = "https://www.truconnect.com/?AgentId=42538&utm_source=" + this.utm_source + "&utm_medium=" + this.utm_medium;
        }
    },
    computed: {

    },
    created() {
        let urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has('utm_source')) {
            this.utm_source = urlParams.get('utm_source')
        }
        if (urlParams.has('utm_medium')) {
            this.utm_medium = urlParams.get('utm_medium')
        }
    }
}
</script>

<style scoped>
.mfpopup {
    position: fixed;
    top: 0;
    width: 100%;
    bottom: 0;
    background: url(../../assets/pfimage/l1fnLj.jpeg);
    z-index: 9999;
}

.bodydiv {
    z-index: 10;
}

.overlay {
    background: #000000b8;
    height: 100%;
    display: block;
    width: 100%;
    position: absolute;
    z-index: 9;
}

.overlay .logo img {
    max-width: 200px;
    margin: 50px;
}

@media screen and (max-width:767px) {
    .bodydiv {
        margin: 0 20px;
        padding: 70px 0 0;
    }
}
</style>
