<template>
<div>
    <div class="section wf-section">
        <div class="div-block">
            <div data-animation="default" data-collapse="medium" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" class="navbar w-nav">
                <a href="#" class="w-nav-brand"><img src="@/assets/images/MyFreePhoneLogo-01W.png" loading="lazy" width="180" sizes="180px" srcset="@/assets/images/MyFreePhoneLogo-01W-p-500.png 500w, @/assets/images/MyFreePhoneLogo-01W-p-800.png 800w, @/assets/images/MyFreePhoneLogo-01W-p-1080.png 1080w, @/assets/images/MyFreePhoneLogo-01W-p-1600.png 1600w, @/assets/images/MyFreePhoneLogo-01W-p-2000.png 2000w, @/assets/images/MyFreePhoneLogo-01W-p-2600.png 2600w, @/assets/images/MyFreePhoneLogo-01W-p-3200.png 3200w, @/assets/images/MyFreePhoneLogo-01W.png 4500w" alt=""></a>
            </div>
        </div>
        <div class="div-block-2">
            <div class="div-block-3">
                <div class="div-block-4">
                    <h1 class="heading-8">FREE Phone</h1>
                    <h1 class="heading">+ Unlimited Talk, Unlimited Text &amp; Data</h1>
                    <p class="paragraph">Sign up to get a FREE Android smartphone with no monthly bill! PLUS FREE international calling to select countries.<br></p>
                    <div class="form-block w-form">
                        <form id="email-form" name="email-form" data-name="Email Form" method="get">
                            <div class="div-block-400">
                                <div class="div-block-401">
                                    <a href="#" @click="showLoader($event)" class="button b w-button">See If You Qualify</a>
                                </div>
                            </div>
                            <div class="text-block-133 b">We will match you to best state programs.</div>
                        </form>
                        <div class="w-form-done">
                            <div>Thank you! Your submission has been received!</div>
                        </div>
                        <div class="w-form-fail">
                            <div>Oops! Something went wrong while submitting the form.</div>
                        </div>
                    </div>
                </div>
                <div class="div-block-5"></div>
            </div>
        </div>
    </div>
    <div class="wf-section">
        <div class="div-block-402">
            <div class="div-block-403">
                <div class="div-block-404"><img src="@/assets/images/Gadgets.png" loading="lazy" sizes="(max-width: 479px) 94vw, (max-width: 991px) 80vw, 36vw" srcset="@/assets/images/Gadgets-p-500.png 500w, @/assets/images/Gadgets-p-800.png 800w, @/assets/images/Gadgets.png 1080w" alt="" class="image-23"></div>
                <div class="div-block-405">
                    <h1 class="heading-2">The Affordable Connectivity Program</h1>
                    <p class="paragraph-2">To receive a free smartphone and monthly service, you or anyone in your household, a child or dependent included, must meet the income-based or program-based eligibility guidelines.<br><br>The program is limited to one ACP benefit per household. </p>
                    <a href="#"  @click="showLoader($event)" class="button w-button">See If You Qualify</a>
                </div>
            </div>
            <div class="div-block-428">
                <div class="text-block-135">Qualifying Programs:</div>
                <div class="div-block-429">
                    <div class="div-block-432"><img src="@/assets/images/Medicaid.png" loading="lazy" width="150" sizes="(max-width: 479px) 38vw, (max-width: 991px) 16vw, 150px" srcset="@/assets/images/Medicaid-p-500.png 500w, @/assets/images/Medicaid-p-800.png 800w, @/assets/images/Medicaid.png 1030w" alt="" class="image-34"><img src="@/assets/images/Seal_of_the_United_States_Department_of_Education.svg.png" loading="lazy" width="80" sizes="(max-width: 479px) 19vw, (max-width: 991px) 8vw, 80px" srcset="@/assets/images/Seal_of_the_United_States_Department_of_Education.svg-p-500.png 500w, @/assets/images/Seal_of_the_United_States_Department_of_Education.svg-p-800.png 800w, @/assets/images/Seal_of_the_United_States_Department_of_Education.svg-p-1080.png 1080w, @/assets/images/Seal_of_the_United_States_Department_of_Education.svg.png 1200w" alt="" class="image-30"></div>
                    <div class="div-block-432"><img src="@/assets/images/Pell-grant-from-ED.jpeg" loading="lazy" width="80" alt="" class="image-30"><img src="@/assets/images/Supplemental_Nutrition_Assistance_Program_logo.svg.png" loading="lazy" width="120" sizes="(max-width: 479px) 32vw, (max-width: 991px) 11vw, 120px" srcset="@/assets/images/Supplemental_Nutrition_Assistance_Program_logo.svg-p-500.png 500w, @/assets/images/Supplemental_Nutrition_Assistance_Program_logo.svg-p-800.png 800w, @/assets/images/Supplemental_Nutrition_Assistance_Program_logo.svg-p-1080.png 1080w, @/assets/images/Supplemental_Nutrition_Assistance_Program_logo.svg.png 1200w" alt="" class="image-31"></div>
                    <div class="div-block-432 _2"><img src="@/assets/images/Housing_and_Urban_Development.svg.png" loading="lazy" width="90" sizes="(max-width: 479px) 19vw, (max-width: 991px) 8vw, 90px" srcset="@/assets/images/Housing_and_Urban_Development.svg-p-500.png 500w, @/assets/images/Housing_and_Urban_Development.svg-p-800.png 800w, @/assets/images/Housing_and_Urban_Development.svg-p-1080.png 1080w, @/assets/images/Housing_and_Urban_Development.svg.png 1200w" alt="" class="image-30"><img src="@/assets/images/wic.png" loading="lazy" width="140" sizes="(max-width: 479px) 38vw, (max-width: 991px) 16vw, 140px" srcset="@/assets/images/wic-p-500.png 500w, @/assets/images/wic.png 1316w" alt="" class="image-33"></div>
                </div>
                <div class="div-block-431">
                    <div class="div-block-432 b"><img src="@/assets/images/lunch-program.png" loading="lazy" width="190" alt="" class="image-27 b"><img src="@/assets/images/ssi.png" loading="lazy" width="80" sizes="(max-width: 479px) 19vw, (max-width: 991px) 8vw, 80px" srcset="@/assets/images/ssi-p-500.png 500w, @/assets/images/ssi.png 512w" alt="" class="image-32"></div>
                    <div class="div-block-432 b _2"><img src="@/assets/images/vA.png" loading="lazy" width="300" sizes="(max-width: 479px) 56vw, 24vw" srcset="@/assets/images/vA-p-500.png 500w, @/assets/images/vA-p-800.png 800w, @/assets/images/vA.png 1000w" alt="" class="image-24"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="wf-section">
        <div class="div-block-409">
            <div class="div-block-410">
                <div class="div-block-408">
                    <h1 class="heading-2 b">Stay Connected On The Go, All The Time.</h1>
                    <p class="paragraph-2 b">Free yourself and your family from the heavy burden of your expensive mobile phone service.<br>‍<br>This offer is available to eligible households receiving Food Stamps or Medicaid benefits. Click the button below to see if you&#x27;re qualified.</p>
                    <a href="#"  @click="showLoader($event)" class="button b w-button">See If You Qualify</a>
                </div>
                <div class="div-block-413"><img src="@/assets/images/hispanics.png" loading="lazy" width="400.5" sizes="(max-width: 479px) 90vw, (max-width: 991px) 38vw, 40vw" srcset="@/assets/images/hispanics-p-500.png 500w, @/assets/images/hispanics-p-800.png 800w, @/assets/images/hispanics.png 801w" alt=""></div>
            </div>
        </div>
    </div>
    <div class="wf-section">
        <div class="div-block-143">
            <h1 class="heading-6">We&#x27;ve given over 15,000 phones</h1>
            <div class="text-block-52">4.6 out of 5 based on 100+ reviews</div>
            <div class="div-block-144">
                <div class="div-block-145">
                    <div class="div-block-445">
                        <div class="div-block-444 _1"></div>
                        <p class="paragraph-6">&quot;My Free Phone helped match me to the best carrier in my area. I got a free Android and a hotspot for internet in my house. I pay zero per month!&quot;<br><br><strong>Tonya from Texas</strong><br></p>
                    </div>
                </div>
                <div class="div-block-145">
                    <div class="div-block-445">
                        <div class="div-block-444 _2"></div>
                        <p class="paragraph-6">&quot;Thanks to My Free Phone, I now have internet connection to use in my home with no monthly payment!&quot;<br><br><br><br><strong>Sharon from Maryland</strong></p>
                    </div>
                </div>
                <div class="div-block-145">
                    <div class="div-block-445">
                        <div class="div-block-444 _3"></div>
                        <p class="paragraph-6">&quot;Legit! I qualified and got a free Android from a carrier near me with the help of My Free Phone. Free minutes and data too!&quot;<br><br><br><strong>Greg from California</strong></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="wf-section">
        <div class="div-block-416">
            <div class="div-block-417">
                <div class="div-block-418">
                    <h1 class="heading-7">Sign Up to Get Your Free Phone &amp; Service</h1>
                    <p class="paragraph-3">Enter your info to check if you qualify for a FREE Android phone and up to 14 GB of monthly data</p>
                    <div class="form-block w-form">
                        <form id="email-form" name="email-form" data-name="Email Form" method="get">
                            <div class="div-block-400">
                                <div class="div-block-401">
                                    <a href="#"  @click="showLoader($event)" class="button b w-button">See If You Qualify</a>
                                </div>
                            </div>
                            <div class="text-block-133 b">We will match you to best state programs.</div>
                        </form>
                        <div class="w-form-done">
                            <div>Thank you! Your submission has been received!</div>
                        </div>
                        <div class="w-form-fail">
                            <div>Oops! Something went wrong while submitting the form.</div>
                        </div>
                    </div>
                </div>
                <div class="div-block-419"><img src="@/assets/images/android-phones06.png" loading="lazy" sizes="(max-width: 479px) 90vw, (max-width: 3000px) 40vw, 1200px" srcset="@/assets/images/android-phones06-p-500.png 500w, @/assets/images/android-phones06-p-800.png 800w, @/assets/images/android-phones06-p-1080.png 1080w, @/assets/images/android-phones06.png 1200w" alt="" class="image-19"></div>
            </div>
        </div>
    </div>
    <div class="wf-section">
        <div class="div-block-79"><img src="@/assets/images/MyFreePhoneLogo-01.png" loading="lazy" width="140" sizes="140px" srcset="@/assets/images/MyFreePhoneLogo-01-p-500.png 500w, @/assets/images/MyFreePhoneLogo-01-p-800.png 800w, @/assets/images/MyFreePhoneLogo-01-p-1080.png 1080w, @/assets/images/MyFreePhoneLogo-01-p-1600.png 1600w, @/assets/images/MyFreePhoneLogo-01-p-2000.png 2000w, @/assets/images/MyFreePhoneLogo-01-p-2600.png 2600w, @/assets/images/MyFreePhoneLogo-01-p-3200.png 3200w, @/assets/images/MyFreePhoneLogo-01.png 4500w" alt="" class="image-9" style="max-width: 140px;">
            <div class="div-block-34">
                <div class="div-block-77">
                    <div class="div-block-76">
                        <div class="text-block-21">
                            <a href="https://go.arlo.ai/privacy/" target="_blank" class="link-5">Privacy Policy</a>
                        </div>
                    </div>
                    <div class="div-block-76">
                        <div class="text-block-21">
                           <!-- <a href="#" class="link-5">Terms of Site</a>-->
                        </div>
                    </div>
                    <div class="div-block-76">
                        <div class="text-block-21">
                            <!--<a href="#" class="link-5">Accessibility</a>-->
                        </div>
                    </div>
                </div>
            </div>
            <div class="div-block-75">
                <div class="div-block-74">
                    <div class="text-block-23">Copyright 2022 My Free Phone</div>
                    <div class="text-block-23">All Rights Reserved</div>
                </div>
            </div>
        </div>
    </div>

    <div class="mfpopup" v-if="show_loader">
        <div class="bodydiv">
            <div class="toppart">
                <div class="toplogo"><img src="@/assets/images/MyFreePhoneLogo-01-p-500.png" width="150" /></div>
                <div class="toptextpart">
                    <h1>We are searching your area </h1>
                    <h1>for free phones programs.</h1>
                </div>
            </div>
            <div class="slider">
                <div class="loader"></div>
                <div class="slide-track">

                    <div class="slide">
                        <img src='@/assets/pfimage/assurance-f.png' />
                    </div>
                    <div class="slide quiken">
                        <img src='@/assets/pfimage/atnt.png' />
                    </div>
                    <div class="slide">
                        <img src='@/assets/pfimage/saflink-f.png' />
                    </div>
                    <div class="slide">
                        <img src='@/assets/pfimage/true-connect-f.png' />
                    </div>
                    <div class="slide">
                        <img src='@/assets/pfimage/verizon-f.png' />
                    </div>

                    <div class="slide quiken">
                        <img src='@/assets/pfimage/atnt.png' />
                    </div>
                    <div class="slide">
                        <img src='@/assets/pfimage/assurance-f.png' />
                    </div>
                    <div class="slide">
                        <img src='@/assets/pfimage/saflink-f.png' />
                    </div>
                    <div class="slide">
                        <img src='@/assets/pfimage/assurance-f.png' />
                    </div>
                    <div class="slide">
                        <img src='@/assets/pfimage/saflink-f.png' />
                    </div>
                    <div class="slide">
                        <img src='@/assets/pfimage/true-connect-f.png' />
                    </div>
                    <div class="slide">
                        <img src='@/assets/pfimage/verizon-f.png' />
                    </div>

                    <div class="slide">
                        <img src='@/assets/pfimage/assurance-f.png' />
                    </div>
                    <div class="slide">
                        <img src='@/assets/pfimage/saflink-f.png' />
                    </div>
                    <div class="slide quiken">
                        <img src='@/assets/pfimage/atnt.png' />
                    </div>
                    <div class="slide">
                        <img src='@/assets/pfimage/assurance-f.png' />
                    </div>
                    <div class="slide">
                        <img src='@/assets/pfimage/saflink-f.png' />
                    </div>
                    <div class="slide">
                        <img src='@/assets/pfimage/true-connect-f.png' />
                    </div>
                    <div class="slide">
                        <img src='@/assets/pfimage/verizon-f.png' />
                    </div>

                    <div class="slide">
                        <img src='@/assets/pfimage/assurance-f.png' />
                    </div>
                    <div class="slide">
                        <img src='@/assets/pfimage/saflink-f.png' />
                    </div>
                    <div class="slide quiken">
                        <img src='@/assets/pfimage/atnt.png' />
                    </div>
                    <div class="slide">
                        <img src='@/assets/pfimage/assurance-f.png' />
                    </div>
                    <div class="slide">
                        <img src='@/assets/pfimage/saflink-f.png' />
                    </div>
                    <div class="slide">
                        <img src='@/assets/pfimage/true-connect-f.png' />
                    </div>
                    <div class="slide">
                        <img src='@/assets/pfimage/verizon-f.png' />
                    </div>

                    <div class="slide">
                        <img src='@/assets/pfimage/assurance-f.png' />
                    </div>
                    <div class="slide">
                        <img src='@/assets/pfimage/saflink-f.png' />
                    </div>
                    <div class="slide quiken">
                        <img src='@/assets/pfimage/atnt.png' />
                    </div>
                    <div class="slide">
                        <img src='@/assets/pfimage/assurance-f.png' />
                    </div>
                    <div class="slide">
                        <img src='@/assets/pfimage/saflink-f.png' />
                    </div>
                    <div class="slide">
                        <img src='@/assets/pfimage/true-connect-f.png' />
                    </div>
                    <div class="slide">
                        <img src='@/assets/pfimage/verizon-f.png' />
                    </div>

                    <div class="slide quiken">
                        <img src='@/assets/pfimage/atnt.png' />
                    </div>
                    <div class="slide">
                        <img src='@/assets/pfimage/assurance-f.png' />
                    </div>
                    <div class="slide">
                        <img src='@/assets/pfimage/saflink-f.png' />
                    </div>
                </div>
            </div>
            <div class="makecenter">
                <div class="pltext">
                    <div class="pulstext"><b>Please wait</b> while we match you <br class="deskhide"> to the top carrier</div>
                    <div class="stage">
                        <div class="dot-pulse"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'HelloWorld',
    data() {
        return {
            utm_source:'internal',
            utm_medium:'organic',
            show_loader: false,
        }
    },
    methods: {
        showLoader(event){
            event.preventDefault();
            let self=this;
            self.show_loader= true;
            setTimeout(function() {
                self.redirectToCarrier();
            }, 5000);
        },
        redirectToCarrier(){
            window.location.href= "https://www.truconnect.com/?AgentId=42538&utm_source="+this.utm_source+"&utm_medium="+this.utm_medium;
        }
    },
    computed: {

    },
    created() {
        let urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has('utm_source')) {
            this.utm_source = urlParams.get('utm_source')
        }
        if (urlParams.has('utm_medium')) {
            this.utm_medium = urlParams.get('utm_medium')
        }
    }
}
</script>

<style>

</style>
